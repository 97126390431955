<template>
    <h1 v-if="!small"
        :style="color != undefined ? `color: ${color}` : null"
        :class="{
            'center': center,
            'black': black,
            
        }"
    >
        <slot />
    </h1>

    <h2 
        v-else
        :style="color != undefined ? `color: ${color}` : null"
        :class="{
            'center': center,
            'black': black,
        }"
    >
        <slot />
    </h2>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#ffffff'
        },

        center: {
            type: Boolean,
            default: false,
        },

        small: {
            type: Boolean,
            default: false,
        },

        black: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'futura';
    src: url('~@/assets/fonts/futuram.woff');
}

@font-face {
    font-family: 'futura-bold';
    src: url('~@/assets/fonts/futurab.woff');
}


h1, h2, h3, h4{
    font-family: 'Futura-Medium', 'futura';
    font-weight: 300;
    margin: rem(10) 0
}

h2 {
    font-size: rem(24)
}

.center {
    text-align: center;
}

.black {
    color: $black !important;
}
</style>